<template>
  <div class="home">
    <el-container>
      <el-header height="200" style="padding: 0;">
        <Nav :showSearch="false"/>
      </el-header>
      <div class="wrapper">
        <div id="building"
             style="align-self:center; text-align: center; width: 680px ;border-radius: 10px;padding-top:20px">
          <div class="title">

          </div>
          <div>
            <el-form ref="loginFormRef" :model="form" :rules="rules">
              <el-tabs >
                <div class="container">
                  <el-form-item prop="username" label="姓名" label-width="120px" >
                    <el-input v-model="form.username" placeholder="请输入姓名"   >
                    </el-input>
                  </el-form-item>

                  <el-form-item prop="phone" label="手机号码" label-width="120px" >
                    <el-input v-model="form.phone" placeholder="请输入手机号码"  ref="phone"  >

                    </el-input>
                  </el-form-item>
                  <el-form-item prop="verifyCode" label="短信验证" label-width="120px" >
                    <el-input v-model="form.verifyCode" placeholder="请输入短信验证"   >
                      <el-button slot="append" type="primary"  class="verify_button"  @click="verifySuccess"  >{{form.verify_button_text}}</el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="password" label="登录密码" label-width="120px" >
                    <el-input v-model="form.password" placeholder="请输登录密码"  show-password  >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="confirm_password" label="确认密码" label-width="120px" >
                    <el-input v-model="form.confirm_password" show-password  placeholder="请再次输入确认密码"   >
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button  style="width: 100%;margin-top:10px;" type="primary"
                               @click="doLogin">注 册
                    </el-button>
                  </el-form-item>

                </div>
              </el-tabs>
            </el-form>
          </div>
          <div>
          </div>
        </div>
      </div>
      <footer2/>
    </el-container>
  </div>
</template>
<script setup>
import footer2 from "components/footer.vue";
import Nav from "@/components/nav";
import {reactive, ref} from "vue";
import CommonService from "@/service/CommonService";
import {Message} from "element-ui";
import router from "@/router";


const loginFormRef = ref();
const form = reactive({
  username: "",
  password: "",
  verifyCode: "",
  phone: "",
  confirm_password: "",
  verify_button_text: "发送验证码",
  number: 60

})





const Timeout=function (){
  if(form.number==60)
  {
    var times=setInterval(function() {
      form.number= form.number-1;
      form.verify_button_text=form.number;
      if(form.number<=0)
      { form.number=60;
        form.verify_button_text="发送验证码";
        clearInterval(times);
      }
      // 需要执行的代码
    }, 1000);
  }
}

const verifySuccess = async (params) => {
  if(form.phone=='')
  {
    Message.error("请输入手机号");
  }else
  {
    Timeout();
    const success = await CommonService.send_verify_code(form.phone).then((res) => {
      if (res.code == 200  || res.code == 0) {
        Message.success("发送成功");
      }else
      {
        Message.success(res.msg);
      }
    });
  }
};



const doLogin = async () => {
  const valid = await loginFormRef.value.validate().catch(() => {
  });
  if (!valid) return false;

  if(form.password!=form.confirm_password)
  {
    Message.error("两次密码输入不一致！");
    return
  }
  CommonService.addCommonUser(form.username,form.password,form.phone,form.verifyCode).then((res) => {
    // console.log("111");
    if (res.code === 200  || res.code == 0) {
      Message.success("注册成功！")
      setTimeout(() => {
        router.push({path: "/login"});
      }, 1000);
    }else {
      Message.error(res.msg);
    }
  })
};

const rules = {
  password: [
    {required: true, message: "请输入密码", trigger: "blur"}
  ],
  username: [
    {required: true, message: "请输入姓名", trigger: "blur"}
  ],verifyCode: [
    {required: true, message: "请输入验证码", trigger: "blur"}
  ],
  phone: [
    {required: true, message: "请输入手机号", trigger: "blur"},
    { type: 'number',
      message: '手机号格式不正确',
      trigger: 'blur',
      transform(value) {
        var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
        if (!phonereg.test(value)) {
          return false
        } else {
          return Number(value)
        }
      }
    }
  ],confirm_password: [
    {required: true, message: "请输入确认密码", trigger: "blur"}
  ]
};
</script>

<style lang="scss" scoped>

.wrapper {
  /*渐变的背景色*/
  /*height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EF8);
  overflow: hidden;*/
  /*背景图*/
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.title {
  text-align: center;
  font-size: 25px;
  color: #3f3f3f;
  margin-bottom: 10px;
  span{
    z-index: 99;padding: 0px 10px; background: whitesmoke;
  }
  hr{
    margin-top: -15px; border: 1px solid gray;
  }
}
::v-deep .el-form-item__label {
  border: 1px solid #DCDFE6;
  text-align: left;
  padding-left: 20px;
  border-right: 0px;
}
::v-deep .el-input__inner {
  border-radius: 0px;
  height: auto;
}

</style>
